<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Наши <span>Курсы</span></h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable tarrif_container">
                    <h3 class="title">Базовый</h3>
                    <div class="price-value">
                        <span class="currency">₽</span>
                        <span class="amount">90.000</span>
                        <!--<span class="month">/Per month</span>-->
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Доступ к информации на период обучения </li>
                        <li><i class="fas fa-check"></i> Сертификат государственного образца о профессиональной переподготовке</li>
                        <li><i class="fas fa-check"></i> Проверка домашних заданий </li>
                        <li><i class="fas fa-check"></i> Доступ в закрытый чат</li>
                        <li><i class="fas fa-check"></i> Чек листы </li>
                        <li><i class="fas fa-check"></i> Список литературы и необходимые сайты </li>
                        <!--
        <li><i class="fas fa-check"></i> 30% разовая скидка </li>
        <li><i class="fas fa-check"></i> Доступ на 30 дней к 5 модулям</li>
        <li><i class="fas fa-check"></i> Сертификат ОПОРЫ РОССИИ </li>
        <li><i class="fas fa-check"></i> Доступ к проверенной базе</li>
        <li><i class="fas fa-check"></i> Проверка домашних заданий </li>
        -->
                    </ul>
                    <a routerLink="/" class="btn btn-primary" (click)="showPaymentForm90()">Купить</a>

                    

                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable tarrif_container">
                    <h3 class="title">Премиум</h3>
                    <div class="price-value">
                        <span class="currency">₽</span>
                        <span class="amount">130.000</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Доступ к информации курса на 1 год </li>
                        <li><i class="fas fa-check"></i> Сертификат государственного образца о профессиональной переподготовке</li>
                        <li><i class="fas fa-check"></i> Доступ к проверенной базе поставщиков, логистов, таможенных кодов, банков</li>
                        <li><i class="fas fa-check"></i> Проверка домашних заданий </li>
                        <li><i class="fas fa-check"></i> Доступ в закрытый чат</li>
                        <li><i class="fas fa-check"></i> 30% скидка на услуги поиска и доставки товара </li>
                        <li><i class="fas fa-check"></i> Возможность получить карту APEC для безвизового въезда в Китай на 5 лет, а также в 18 стран содружества со скидкой в 50 % </li>
                        <li><i class="fas fa-check"></i> Индивидуальная консультация от экспертов </li>
                        <li><i class="fas fa-check"></i> Чек листы </li>
                        <li><i class="fas fa-check"></i> Список литературы и необходимые сайты </li>
                        <!--
    <li><i class="fas fa-check"></i> 30% разовая скидка </li>
    <li><i class="fas fa-check"></i> Доступ на 30 дней к 10 модулям</li>
    <li><i class="fas fa-check"></i> Сертификат ОПОРЫ РОССИИ </li>
    <li><i class="fas fa-check"></i> Доступ к проверенной базе</li>
    <li><i class="fas fa-check"></i> Доступ к чату с бизнес-аналитиками </li>
    <li><i class="fas fa-check"></i> Проверка домашних заданий </li>
    <li><i class="fas fa-check"></i> Индивидуальная консультация от экспертов </li>
    <li><i class="fas fa-check"></i> Список проверенной литературы </li>
    -->
                    </ul>
                    <a routerLink="/" class="btn btn-primary" (click)="showPaymentForm130()">Купить</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable tarrif_container">
                    <h3 class="title">Личный Аналитик</h3>
                    <div class="price-value">
                        <span class="currency">₽</span>
                        <span class="amount">230.000</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Доступ к информации курса бессрочно </li>
                        <li><i class="fas fa-check"></i> Сертификат государственного образца о профессиональной переподготовке</li>
                        <li><i class="fas fa-check"></i> Доступ к проверенной базе поставщиков, логистов, таможенных кодов, банков </li>
                        <li><i class="fas fa-check"></i> Проверка домашних заданий</li>
                        <li><i class="fas fa-check"></i> Доступ в закрытый чат </li>
                        <li><i class="fas fa-check"></i> 30% скидка на услуги поиска и доставки товара </li>
                        <li><i class="fas fa-check"></i> Возможность бесплатно получить карту APEC для безвизового  въезда в Китай  на 5 лет, а также в 18 стран содружества </li>
                        <li><i class="fas fa-check"></i> Индивидуальная консультация от экспертов </li>
                        <li><i class="fas fa-check"></i> Vip-чат с экспертом </li>
                        <li><i class="fas fa-check"></i> 2 Индивидуальных разбора вашего кейса  </li>
                        <li><i class="fas fa-check"></i> Чек листы  </li>
                        <li><i class="fas fa-check"></i> Список литературы и необходимые сайты  </li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary" (click)="showPaymentForm230()">Купить</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
