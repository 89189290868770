<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4 class="h44">ОПОРА РОССИИ имеет прочные связи с китайскими бизнес-ассоциациями</h4> 
            <h2>Наши <span>Партнеры</span></h2>
            <p>Крупнейшие китайские бизнес-ассоциации и объединения предпринимателей.</p>
        </div>
        <div class="partners-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner1.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Китайская ассоциация международного сотрудничества</h3>
                            <span class="post">КНР</span>
                        </div>
                        <div class="social">
                            <!--
                    <ul>
                        <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                        <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                        <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
                        <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                    </ul>
                -->
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner2.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">
                                Торговая палата провинции Гуандун
                            </h3>
                            <span class="post">Гуандун, КНР</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Народное правительство провинции</h3>
                            <span class="post">Хэйлунцзян, КНР</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Китайская Торговая палата </h3>
                            <span class="post">провинция Гуандун</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner5.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Пекинская Торговая Палата</h3>
                            <span class="post">ц
                                Пекин, КНР
                            </span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner6.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Совет по развитию торговли Гонконга, Гонконгская ассоциация МСП</h3>
                            <span class="post">Гонконг</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner7.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Институт Стран Азиии и Африки</h3>
                            <span class="post">Москва, Россия</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner8.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Юридическая фирма DHH</h3>
                            <span class="post">Пекин, КНР</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-partner-box">
                        <div class="image">
                            <img src="assets/img/partner9.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Академия AsiaAtica</h3>
                            <span class="post">Москва, Россия</span>
                        </div>
                        <div class="social">
                        </div>
                    </div>
                </ng-template>

            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
