import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ApiService } from '../../../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    private senderEmail = 'app.askme2@gmail.com';
    private receiverEmail = 'kon90@mail.ru';

    constructor(
        private viewportScroller: ViewportScroller,
        private apiService: ApiService,
        private snackBar: MatSnackBar
    ) { }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    submit(form) {

        var formValues = form.value;
        var name = formValues.name;
        var email = formValues.email;
        var number = formValues.number;
        var subject = formValues.subject;
        var message = formValues.message;

        var id = Math.floor(Math.random() * (1000000000));

        const jsonData =
        {
            id: id,
            from_email: this.senderEmail,
            from_name: name,
            to: this.receiverEmail,
            subject: 'Лэндинг Опора Образование: '+subject,
            text: 'test',
            html: `<!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Сообщение с сайта</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  line-height: 1.6;
                  background-color: #f9f9f9;
                  margin: 0;
                  padding: 0;
                }
                .email-container {
                  max-width: 600px;
                  margin: 20px auto;
                  background: #ffffff;
                  padding: 20px;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
                .header {
                  text-align: center;
                  border-bottom: 1px solid #eee;
                  margin-bottom: 20px;
                  padding-bottom: 10px;
                }
                .header h1 {
                  margin: 0;
                  font-size: 24px;
                  color: #333;
                }
                .content {
                  margin-bottom: 20px;
                }
                .content p {
                  margin: 10px 0;
                }
                .footer {
                  text-align: center;
                  font-size: 12px;
                  color: #777;
                }
              </style>
            </head>
            <body>
              <div class="email-container">
                <div class="header">
                  <h1>Новое сообщение с сайта: `+ subject +`</h1>
                </div>
                <div class="content">
                  <p><strong>Имя отправителя:</strong> `+ name +`</p>
                  <p><strong>Email для связи:</strong> `+ email +`</p>
                  <p><strong>Телефон:</strong> `+ number +`</p>
                  <p><strong>Сообщение:</strong></p>
                  <p>`+ message +`</p>
                </div>
                <div class="footer">
                  <p>Это сообщение было отправлено через форму обратной связи на сайте.</p>
                </div>
              </div>
            </body>
            </html>
        `
        }; 

        this.apiService.sendEmailMessage(jsonData).subscribe(
            response => {
                ///console.log('Успех:', response);
                this.snackBar.open('Сообщение отправлено!', 'Закрыть', {
                    duration: 3000, 
                    horizontalPosition: 'center', 
                    verticalPosition: 'bottom'
                });
                form.reset({
                    name: '',
                    email: '',
                    number: '',
                    subject: '',
                    message: ''
                });

            },
            error => {
                ///console.error('Ошибка:', error);
                this.snackBar.open('Произошла ошибка! Пожалуйста попробуйте позже.', 'Закрыть', {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom'
                });
            }
        );

    }

}
