<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Бизнес-миссия в <span>Китай!</span></h2>
            <p>
                Хотите закрепить результат? После прохождения курса вы можете поехать в Бизнес-Миссию в Китай и на практике закрепить все полученные знания!
                ОПОРА РОССИИ несколько раз в год проводит бизнес-миссии в Китай!
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services mission_info_container">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Посещение производств</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services mission_info_container">
                    <div class="services-img">
                        <img src="assets/img/services-img2.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>
                            Посещение выставок
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services mission_info_container">
                    <div class="services-img">
                        <img src="assets/img/services-img3.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Контакты с китайскими производителями</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services mission_info_container">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Контакты с китайскими бизнес-ассоциациями</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services mission_info_container">
                    <div class="services-img">
                        <img src="assets/img/services-img5.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Запуск контрактного производства</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services mission_info_container">
                    <div class="services-img">
                        <img src="assets/img/services-img6.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>И многое другое</h3>
                    </div>
                </div>
            </div>

            <a (click)="onClick('contact')" class="btn btn-primary sign_in_button ">Записаться на бизнес-миссию</a>
        </div>
    </div>
</div>
