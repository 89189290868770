<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h2>Наша <span>Стратегия</span></h2>
                    </div>
                    <ul class="features-list">
                        <li>
                            <i class="fa fa-check"></i>Курс детально отобранной информации, теоретической и практической части, каждая из которых рассчитана на месяц упорной работы 
                        </li>
                        <li>
                            <i class="fa fa-check"></i>Отдельная платформа для обучения
                        </li>
                        <li><i class="fa fa-check"></i>Домашние задания под каждым уроком и их курирование</li>
                        <li>
                            <i class="fa fa-check"></i>Закрытый чат сообщества Опора Образование
                        </li>
                        <li>
                            <i class="fa fa-check"></i>Прямые консультации и разборы задач с экспертами
                        </li>
                        <li>
                            <i class="fa fa-check"></i>Знания без воды
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
