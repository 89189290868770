<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <a *ngIf="isSticky" class="navbar-brand logo" routerLink="/"><img src="assets/img/opora_logo_black.png" /></a>
        <a *ngIf="!isSticky" class="navbar-brand logo" routerLink="/"><img src="assets/img/opora_logo.png" /></a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Главная</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('welcome')">О нас</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">Китай</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Курсы</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Контакты</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Главная</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('welcome')">О нас</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">Китай</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Курсы</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Контакты</span></li>
            </ul>
        </div>
    </div>
</nav>
