<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <span class="h3c">НАЧНИ РАБОТАТЬ С КИТАЕМ</span>
                    <h1>CHINA <span>BUSINESS</span> SCHOOL</h1>
                    <p class="pc">Мы запускаем  новый масштабный проект «ОПОРА ОБРАЗОВАНИЕ»!</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Начать</a>
                    <a (click)="onClick('pricing')" class="btn btn-primary view-work">Курсы</a>

                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-cta></app-cta>

<app-team></app-team>

<app-work></app-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-funfacts></app-funfacts>

<app-strategy></app-strategy>

<china-mission></china-mission>

<app-who-we-are></app-who-we-are>

<education-system></education-system>

<app-pricing></app-pricing>

<app-feedback></app-feedback>

<our-experts></our-experts>

<app-faq></app-faq>

<app-contact></app-contact>



<!--
<app-skill></app-skill>

<app-how-we-work></app-how-we-work>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>
-->
