<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Хотите такие же <span>Результаты?</span></h2>
            <p>Вот несколько примеров успешных кейсов наших учеников</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <!--<ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Brand
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Design
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Graphic
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                    <span (click)="switchTab($event, 'tab5')">
                        Photoshop
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                    <span (click)="switchTab($event, 'tab6')">
                        Illustrator
                    </span>
                </li>
            </ul>
            -->
            <div class="tabs-container ">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-3 col-md-4">
                            <div class="single-work">
                                <img src="assets/img//results/result_1.png" alt="work-img">
                                <!--
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                                -->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                            <div class="single-work">
                                <img src="assets/img//results/result_2.png" alt="work-img">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                            <div class="single-work">
                                <img src="assets/img//results/result_3.png" alt="work-img">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                            <div class="single-work">
                                <img src="assets/img//results/result_4.png" alt="work-img">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
