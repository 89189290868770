<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Что вы <span>Получите?</span></h2>
            <p>
                Наша миссия помочь вам сделать успешный бизнес с Китаем! Мы собрали все накопленные за много лет знания и готовы ими поделиться! Почему вам обязательно нужен этот курс? 
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services what_you_got_container_big">
                    <div class="services-img">
                        <img src="assets/img/theachers.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-user-graduate"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Наши кураторы</h3>
                        <p>
                            это эксперты, проживающие непосредственно на территории Китая и знающие правила и особенности ведения бизнеса. Это позволяет нам внедрять последние тренды и новинки при работе с клиентами. 
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services what_you_got_container_big">
                    <div class="services-img">
                        <img src="assets/img/quality.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Качество</h3>
                        <p>
                            Мы действительно проработали каждый из наших модулей, чтобы вы получили максимум знаний и навыков для работы с китайскими партнерами.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services what_you_got_container_big">
                    <div class="services-img">
                        <img src="assets/img/timeline.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-business-time"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Сроки</h3>
                        <p>Нашей целью в составлении курсов была максимальная полезность контента и отсутствие ненужной «воды», что позволит вам сохранить время, с умом и пользой завершить наш онлайн-курс. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services what_you_got_container_small">
                    <div class="services-img">
                        <img src="assets/img/support.jpg" alt="services-img">
                        <div class="icon">
                            <i class="far fa-circle-question"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Обратная связь</h3>
                        <p>
                            Практическая часть, а также включённые в курс онлайн-консультации дадут ответы на все вопросы. 
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services what_you_got_container_small">
                    <div class="services-img">
                        <img src="assets/img/usefulness.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-hands-helping"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Полезность</h3>
                        <p>
                            Эксклюзивная информация, проверенные лично нами Контакты (логистика, маркетинг, PR и многое другое).
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services what_you_got_container_small">
                    <div class="services-img">
                        <img src="assets/img/opportunity.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-suitcase"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Возможность</h3>
                        <p>
                            В кратчайшие сроки запустить успешный бизнес с Китаем.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
