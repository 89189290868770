<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Что получите после <span>Курса?</span></h2>
            <p>Знания, которые вы получите пройдя курс обучения, станут прекрасным фундаментом для вашего бизнеса с Китаем</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <!--<input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Speed , flexibility</label> -->
          
                <div class="tab-panels">

                    <div id="speed-flexibility">
                        <!--class="tab-panel"-->
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="why-we-different-img">
                                    <img src="assets/img/different-img2.jpg" alt="img">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="why-we-different-content">
                                    <h3>С курсом «Внешнеэкономическая деятельность: Торговля с Китаем» вы получите:</h3>
                                    <ul>
                                        <li>
                                            <i class="fas fa-check"></i> Возможность вырасти с 0 до 3 млн. руб/мес за 3 месяца
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i> Узнаете, как начать работу с Китаем 
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i> Научитесь самостоятельно искать производителей в Китае
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i> Поймете, как правильно вести переговоры, какие вопросы задавать и как получать лучшую цену на переговорах с китайскими поставщиками 
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i> Научитесь заказывать товар под собственным брендом
                                        </li>
                                        <li>
                                            <i class="fas fa-check"></i> Сформулируйте для себя удобный и безопасный формат работы с китайскими поставщиками
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--
        <div id="profeesional-work" class="tab-panel">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="why-we-different-img">
                        <img src="assets/img/different-img1.jpg" alt="img">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="why-we-different-content">
                        <h3>Profeesional work</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <ul>
                            <li><i class="fas fa-check"></i> Creative Design</li>
                            <li><i class="fas fa-check"></i> Retina Ready</li>
                            <li><i class="fas fa-check"></i> Responsive Design</li>
                            <li><i class="fas fa-check"></i> Modern Design</li>
                            <li><i class="fas fa-check"></i> Awesome Design</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->

                </div>
</div>
    </div>
</div>
