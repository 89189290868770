import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private emailApiUrl = 'https://api.notisend.ru/v1/email/messages';
    private emailApiKey = '762c68332fc753a5fbc1b0a7dd9a12be';

    constructor(private http: HttpClient) { }

    sendEmailMessage(data: any): Observable<any> {
        return this.http.post(this.emailApiUrl, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.emailApiKey
            }
        });
    }
}
