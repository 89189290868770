<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Внешнеэкономическая деятельность: Торговля с Китаем</h4>
            <h2><span>Описание</span></h2>
            <p>
                ОПОРА РОССИИ запускает Уникальный обучающий бизнес-курс с практическими заданиями, после которого вы научитесь самостоятельно работать с Поднебесной!
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-ruble-sign"></i>
                    </div>
                    <h3 class="h3">Пройдя курс, вы освоите товарный бизнес с Китаем</h3>
                    <p>
                    </p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div> 
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-graduation-cap"></i>
                    </div>
                    <h3 class="h3">Получите новые знания, идеи, товары, поставщиков</h3>
                    <p></p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box description_container">
                    <div class="icon">
                        <i class="fas fa-key"></i>
                    </div>
                    <h3 class="h3">Получите ключ к бизнесу с Китаем</h3>
                    <p></p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
